<template>
  <div class="article">
    <div class="contents">
      <div class="tbl_option">
        <button class="btn_sub1" @click="showLotSearch = true">
          작업지시서 검색
        </button>
        <div class="input_search">
          <input
            type="text"
            placeholder="제품LOT검색"
            :value="search_text"
            @input="typing($event)"
          />
          <button>
            <i class="fa fa-search" @click="searchLot()"></i>
          </button>
        </div>
      </div>
      <div class="cont">
        <div class="inspection_list">
          <h6>검사항목</h6>
          <div class="status_table">
            <h6>조회수 : {{ check_lists.length }}건</h6>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup
                  class="fare_col"
                  :class="{ permission_on: isPermissionOn }"
                >
                  <col
                    v-for="(n, index) in isPermissionOn ? 7 : 6"
                    :key="index"
                  />
                </colgroup>
                <thead>
                  <tr>
                    <th>등록일시</th>
                    <th>공정</th>
                    <th>SEQ</th>
                    <th>통과수량</th>
                    <th>불량수량</th>
                    <th>검사현황</th>
                    <th v-if="isPermissionOn">삭제</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in check_lists"
                    :key="item.id"
                    @click="selectRow(index)"
                    :class="{ active: selectedIndex == index }"
                  >
                    <td>{{ item.create_time | formatDate }}</td>
                    <td>
                      {{ findInfoFromId(process, item.process_id).name }}
                    </td>
                    <td>{{ item.seq_number }}</td>
                    <td>{{ item.seq_pass_quantity | makeComma }}</td>
                    <td>{{ item.seq_fail_quantity | makeComma }}</td>
                    <td>
                      {{ item.verification_state == 'N' ? '대기' : '검사완료' }}
                    </td>
                    <td v-if="isPermissionOn">
                      <button
                        v-if="item.seq_quality_id != null"
                        @click="
                          () => {
                            my_modal_index = index;
                            my_isModalOpen = true;
                          }
                        "
                        class="tbl_delete_btn"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="progress_cont">
          <h6>검사진행</h6>
          <div class="mes_tbl_wrap">
            <table class="mes_tbl">
              <colgroup
                class="fare_col"
                :class="{ permission_on: isPermissionOn }"
              >
                <col
                  v-for="(n, index) in isPermissionOn ? 11 : 10"
                  :key="index"
                />
              </colgroup>
              <thead>
                <tr>
                  <th>순서</th>
                  <th>검사항목</th>
                  <th>합격기준</th>
                  <th>검사기구</th>
                  <th>검사시기</th>
                  <th>검사수량</th>
                  <th>합격수량</th>
                  <th>불합격 수량</th>
                  <th>합격여부</th>
                  <th>첨부이미지</th>
                  <th v-if="isPermissionOn">삭제</th>
                </tr>
              </thead>
              <tbody v-if="selectedIndex != -1">
                <tr v-for="(item, index) in managementData" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <my-local-selectric
                      v-if="showSelectric"
                      :id="`verification_selectric${index}`"
                      :watch="item.verification_id"
                      :state="
                        managementData == undefined ||
                        index != managementData.length - 1 ||
                        !isPermissionOn
                          ? 'disabled'
                          : ''
                      "
                      :options="
                        seq_verification_options.filter(
                          x =>
                            x.value == item.verification_id ||
                            !filter_veri_options.includes(x.value),
                        )
                      "
                      @changeValue="setVeri($event, index)"
                    ></my-local-selectric>
                  </td>
                  <td>
                    {{
                      item.verification_id != null
                        ? findInfoFromId(
                            seq_verifications,
                            item.verification_id,
                          ).pass_standard
                        : '항목선택 후 자동입력'
                    }}
                  </td>
                  <td>
                    {{
                      item.verification_id != null
                        ? findInfoFromId(
                            seq_verifications,
                            item.verification_id,
                          ).inspection_equipment
                        : '항목선택 후 자동입력'
                    }}
                  </td>
                  <td>
                    {{
                      item.verification_id != null
                        ? findInfoFromId(
                            seq_verifications,
                            item.verification_id,
                          ).inspection_timing
                        : '항목선택 후 자동입력'
                    }}
                  </td>
                  <td>{{ item.check_quantity | makeComma }}</td>
                  <td>
                    <input
                      type="text"
                      class="tbl_input"
                      :value="$makeComma(item.pass_quantity)"
                      :disabled="
                        managementData == undefined ||
                          index != managementData.length - 1 ||
                          !isPermissionOn
                      "
                      inputmode="decimal"
                      @input="
                        $inputNumber(
                          $event,
                          item,
                          'pass_quantity',
                          $makeNumber(item.check_quantity),
                          0,
                        );
                        inputFailQuantity(
                          $makeNumber($event.target.value),
                          $makeNumber(item.check_quantity),
                          index,
                        );
                      "
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      class="tbl_input"
                      :value="$makeComma(item.unpass_quantity)"
                      :disabled="
                        managementData == undefined ||
                          index != managementData.length - 1 ||
                          !isPermissionOn
                      "
                      inputmode="decimal"
                      @input="
                        $inputNumber(
                          $event,
                          item,
                          'unpass_quantity',
                          $makeNumber(item.check_quantity),
                          0,
                        );
                        inputPassQuantity(
                          $makeNumber($event.target.value),
                          $makeNumber(item.check_quantity),
                          index,
                        );
                      "
                    />
                  </td>
                  <td>
                    <my-local-selectric
                      v-if="showSelectric"
                      :id="`pass_selectric${index}`"
                      :watch="item.pass_yn"
                      :state="
                        managementData == undefined ||
                        index != managementData.length - 1 ||
                        !isPermissionOn
                          ? 'disabled'
                          : ''
                      "
                      :options="pass_options"
                      @changeValue="setPassYn($event, index)"
                    ></my-local-selectric>
                  </td>
                  <td>
                    <button
                      class="btn_tbl"
                      :disabled="!isPermissionOn"
                      @click="
                        () => {
                          managementSelectedIndex = index;
                          showImageModal = true;
                        }
                      "
                    >
                      {{ item.image != null ? '수정' : '등록' }}
                    </button>
                  </td>
                  <td v-if="isPermissionOn" @click="deleteRow(index)">
                    <button
                      v-if="
                        managementData != undefined &&
                          index == managementData.length - 1 &&
                          isPermissionOn
                      "
                      class="tbl_delete_btn"
                    >
                      delete
                    </button>
                  </td>
                </tr>
                <tr
                  class="new"
                  v-if="
                    managementData != undefined &&
                      managementData.length > 0 &&
                      managementData[managementData.length - 1]
                        .verification_id != null &&
                      isPermissionOn
                  "
                >
                  <td colspan="11" @click="addNewRow()">
                    +신규행 등록
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btn_wrap">
          <button
            class="btn_sub2"
            @click="submitForm"
            v-if="
              (valid_data ||
                (selectedIndex != -1 &&
                  check_lists[selectedIndex].seq_quality_id != null)) &&
                isPermissionOn
            "
            :disabled="isValidModify || !valid_data"
          >
            {{
              check_lists[selectedIndex].seq_quality_id == null
                ? '검사완료'
                : '수정완료'
            }}
          </button>
        </div>
      </div>
    </div>
    <new-verification
      v-if="showModal"
      :verification_type_id="2"
      @onclose="showModal = false"
    ></new-verification>
    <div class="modal_layer" v-if="showModal" @click="showModal = false"></div>
    <upload-image-modal
      v-if="showImageModal"
      :uploadedImage="managementData[managementSelectedIndex].image"
      :index="managementSelectedIndex"
      @onclose="showImageModal = false"
      @onselect="
        $event => {
          selectImage($event);
          showImageModal = false;
        }
      "
    ></upload-image-modal>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        $event => {
          deleteSeqQuality(my_modal_index);
          my_isModalOpen = false;
        }
      "
    ></two-button-modal>
    <lot-search
      v-if="showLotSearch"
      :filterWaiting="true"
      @onclose="showLotSearch = false"
      @onselect="
        $event => {
          selectLot($event);
          showLotSearch = false;
        }
      "
    ></lot-search>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import fetchMixin from '@/mixins/fetch';
import spinnerMixin from '@/mixins/spinner';
import modalMixin from '@/mixins/modal';
import FavoriteMixin from '@/mixins/favorite';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import NewVerification from '@/layouts/components/NewVerification';
import UploadImageModal from '@/layouts/components/UploadImageModal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

import LotSearch from '@/layouts/components/search-popup/LotSearch';
export default {
  mixins: [fetchMixin, spinnerMixin, modalMixin, FavoriteMixin],
  components: {
    LotSearch,
    MyLocalSelectric,
    NewVerification,
    UploadImageModal,
    TwoButtonModal,
  },
  data() {
    return {
      showSelectric: false,
      showModal: false,
      showLotSearch: false,
      showImageModal: false,
      managementSelectedIndex: -1,
      pass_options: [
        {
          label: '합격',
          value: true,
        },
        {
          label: '불합격',
          value: false,
        },
      ],

      //delete modal
      my_modal_title: '삭제경고',
      my_modal_content: '정말로 삭제하시겠습니까?',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      managementData: 'getManagementDataFromSeqVerification',
      modifyChkData: 'getModifyChkDataFromSeqVerification',
      filtered_check_lists: 'getCheckListFromSeqVerification',
      selectedIndex: 'getSelectedIndexFromSeqVerification',
      process: 'getProcess',
      companys: 'getCompany',
      units: 'getUnitCodes',
      seq_verifications: 'getSeqVerificationFromQualityManagement',
      seq_verification_options:
        'getSeqVerificationForSelectricFromQualityManagement',
      systemCompany: 'getSystemCompany',
      search_text: 'getSearchTextFromSeqVerification',
    }),
    check_lists() {
      let checkList = this.lodash
        .clonedeep(this.filtered_check_lists)
        .sort((a, b) => a.lot_seq_id - b.lot_seq_id)
        .sort((a, b) => a.process_id - b.process_id);

      return checkList;
    },
    filter_veri_options() {
      if (this.seq_verifications.length > 0) {
        if (this.managementData.length > 1) {
          const concatData = this.lodash.clonedeep(this.managementData);
          let list = [];
          concatData.forEach(element => {
            list.push(element.verification_id);
          });
          return list;
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
    valid_data() {
      if (this.managementData == undefined || this.managementData.length < 1) {
        return false;
      } else if (
        this.managementData[this.managementData.length - 1].verification_id ==
        null
      ) {
        return false;
      } else {
        const target = this.lodash.clonedeep(
          this.managementData[this.managementData.length - 1],
        );
        if (
          this.$makeNumber(target.check_quantity) !=
          this.$makeNumber(target.pass_quantity) +
            this.$makeNumber(target.unpass_quantity)
        ) {
          return false;
        } else {
          const sumFail = this.managementData
            .map(x => x.unpass_quantity)
            .reduce((a, b) => this.$makeNumber(a) + this.$makeNumber(b), 0);
          if (
            sumFail > this.check_lists[this.selectedIndex].seq_fail_quantity
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    isValidModify() {
      if (this.managementData != null) {
        const originalData = JSON.stringify(
          this.lodash.clonedeep(this.modifyChkData),
        );

        let modifyData = this.lodash.clonedeep(this.managementData);
        modifyData.forEach(x => {
          x.pass_quantity = this.$makeNumber(x.pass_quantity);
          x.unpass_quantity = this.$makeNumber(x.unpass_quantity);
        });

        modifyData = JSON.stringify(modifyData);

        if (modifyData == originalData) {
          return true;
        } else return false;
      } else return true;
    },
  },
  methods: {
    async selectLot(e) {
      this.selectRow(-1);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_SEQ_VERIFICATION_DEFECT',
        e.id,
        '공정검사',
      );
    },
    typing(e) {
      this.$store.commit('setSearchTextToSeqVerification', e.target.value);
    },
    searchLot() {
      if (this.search_text.length == 13) {
        let lot_str = this.search_text.substr(8);
        lot_str = Number(lot_str);
        console.log(lot_str);
        this.selectLot({ id: lot_str });
      } else {
        this.openOneButtonModal(
          '올바른 LOT번호를 입력해주세요',
          '작업지시서로 검색할 수 있습니다.',
        );
      }
    },
    async deleteSeqQuality(index) {
      this.showSpinner();
      await this.$store
        .dispatch('DELETE_SEQ_QUALITY', this.check_lists[index].seq_quality_id)
        .then(async () => {
          this.openOneButtonModal('삭제 성공', '검사 결과를 삭제하였습니다.');
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_SEQ_VERIFICATION_DEFECT',
            this.check_lists[index].lot_id,
            '검사',
          );
        })
        .catch(() => {
          this.openOneButtonModal('삭제 실패', '삭제 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async submitForm() {
      const payload = this.lodash.clonedeep(
        this.check_lists[this.selectedIndex],
      );
      payload.check_quantity =
        this.$makeNumber(payload.seq_pass_quantity) +
        this.$makeNumber(payload.seq_fail_quantity);

      payload.unpass_quantity = this.managementData
        .map(x => x.unpass_quantity)
        .reduce((a, b) => this.$makeNumber(a) + this.$makeNumber(b), 0);

      payload.pass_quantity =
        this.$makeNumber(payload.check_quantity) -
        this.$makeNumber(payload.unpass_quantity);

      payload.seq_defect_list = this.lodash.clonedeep(this.managementData);
      payload.seq_defect_list.forEach(el => {
        el.lot_seq_id = payload.id;
        el.check_quantity = this.$makeNumber(el.check_quantity);
        el.pass_quantity = this.$makeNumber(el.pass_quantity);
        el.unpass_quantity = this.$makeNumber(el.unpass_quantity);
      });
      payload.saup_no = this.systemCompany.saup_no;

      if (payload.seq_quality_id != null) {
        //수정
        this.showSpinner();
        await this.$store
          .dispatch('UPDATE_SEQ_QUALITY', payload)
          .then(async () => {
            this.openOneButtonModal('수정 성공', '검사 결과를 수정하였습니다.');
            this.selectRow(-1);
            this.FETCH_WITH_PAYLOAD(
              'FETCH_SEQ_VERIFICATION_DEFECT',
              payload.lot_id,
              '검사',
            );
          })
          .catch(() => {
            this.openOneButtonModal('수정 실패', '수정 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        //등록
        this.showSpinner();
        await this.$store
          .dispatch('INSERT_SEQ_QUALITY', payload)
          .then(async () => {
            this.openOneButtonModal('등록 성공', '검사 결과를 등록하였습니다.');
            this.selectRow(-1);
            this.FETCH_WITH_PAYLOAD(
              'FETCH_SEQ_VERIFICATION_DEFECT',
              payload.lot_id,
              '검사',
            );
          })
          .catch(() => {
            this.openOneButtonModal('등록 실패', '등록 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      }
    },
    inputFailQuantity(passQ, totalQ, index) {
      this.managementData[index].unpass_quantity = totalQ - passQ;
    },
    inputPassQuantity(failQ, totalQ, index) {
      this.managementData[index].pass_quantity = totalQ - failQ;
    },
    selectImage(arg) {
      console.log(typeof arg.image);
      if (arg.image != 'null') {
        this.managementData[arg.index].image = arg.image;
      }
    },
    setPassYn(arg, index) {
      this.managementData[index].pass_yn = arg;
    },
    setVeri(arg, index) {
      if (arg == 'new') {
        this.showModal = true;
        this.managementData[index].verification_id = null;
      } else {
        this.managementData[index].verification_id = arg;
      }
    },
    addNewRow() {
      const beforePass = Number(
        String(
          this.managementData[this.managementData.length - 1].pass_quantity,
        ).replace(/,/gi, ''),
      );
      const beforeFail = Number(
        String(
          this.managementData[this.managementData.length - 1].unpass_quantity,
        ).replace(/,/gi, ''),
      );
      const beforeCheck = Number(
        String(
          this.managementData[this.managementData.length - 1].check_quantity,
        ).replace(/,/gi, ''),
      );
      const sumFail = this.managementData
        .map(x => x.unpass_quantity)
        .reduce((a, b) => this.$makeNumber(a) + this.$makeNumber(b), 0);
      console.log(sumFail);
      if (beforePass <= 0) {
        this.openOneButtonModal(
          '행 생성 불가',
          '이전 검사의 합격수량이 없을 경우<br/> 검사할 수 없습니다.',
        );
        return;
      } else if (beforeCheck != beforePass + beforeFail) {
        this.openOneButtonModal(
          '행 생성 불가',
          '합격수량과 불합격수량의 합은 검사수량과 같아야합니다.',
        );
        return;
      } else if (
        sumFail > this.check_lists[this.selectedIndex].seq_fail_quantity
      ) {
        this.openOneButtonModal(
          '행 생성 불가',
          '불합격 수량의 총합은 선택 공정의 불량수량을 넘을 수 없습니다.',
        );
        return;
      } else {
        this.managementData.push({
          id: this.managementData.length + 1,
          verification_id: null,
          check_quantity: this.managementData[this.managementData.length - 1]
            .pass_quantity,
          pass_quantity: 0,
          unpass_quantity: 0,
          pass_yn: true,
          image: null,
        });
      }
    },
    deleteRow(index) {
      if (index != this.managementData.length - 1) {
        return;
      }
      this.managementData.splice(index, 1);
      if (this.managementData.length < 1) {
        this.managementData.push({
          id: 1,
          verification_id: null,
          check_quantity:
            this.$makeNumber(
              this.check_lists[this.selectedIndex].seq_pass_quantity,
            ) +
            this.$makeNumber(
              this.check_lists[this.selectedIndex].seq_fail_quantity,
            ),
          pass_quantity: 0,
          unpass_quantity: 0,
          pass_yn: true,
          image: null,
        });
      }
    },
    async selectRow(index) {
      this.$store.commit('setSelectedIndexToSeqVerification', index);
      if (index != -1) {
        if (this.check_lists[index].seq_quality_id != null) {
          await this.FETCH_WITH_PAYLOAD(
            'FETCH_SEQ_DEFECT',
            this.check_lists[index].seq_quality_id,
            '검사',
          );
          this.$store.commit(
            'setModifyChkDataToSeqVerification',
            this.lodash.clonedeep(this.managementData),
          );
        } else {
          const newManage = [];
          newManage.push({
            id: 1,
            verification_id: null,
            check_quantity:
              this.$makeNumber(
                this.check_lists[this.selectedIndex].seq_pass_quantity,
              ) +
              this.$makeNumber(
                this.check_lists[this.selectedIndex].seq_fail_quantity,
              ),
            pass_quantity: 0,
            unpass_quantity: 0,
            pass_yn: true,
            image: null,
          });
          this.$store.commit('setManagementDataToSeqVerification', newManage);
          this.$store.commit(
            'setModifyChkDataToSeqVerification',
            this.lodash.clonedeep(newManage),
          );
        }
      } else {
        this.$store.commit('setManagementDataToSeqVerification', null);
        this.$store.commit('setModifyChkDataToSeqVerification', null);
      }
    },
  },
  async created() {
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }

    if (this.seq_verifications.length < 1) {
      await this.FETCH('FETCH_BASE_VERIFICATION');
    }
    if (this.systemCompany.id == undefined) {
      this.FETCH('FETCH_SYSTEM_COMPANY', '사용자 사업자');
    }

    // if (this.check_lists.length < 1) {
    //   await this.FETCH_WITH_PAYLOAD(
    //     'FETCH_SEQ_VERIFICATION_DEFECT',
    //     {
    //       start: this.start_date,
    //       end: this.end_date,
    //     },
    //     '공정검사',
    //   );
    // }
    this.showSelectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
